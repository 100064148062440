import {
  RiFacebookBoxFill,
  RiLinkedinFill,
  RiTwitterFill,
  RiWhatsappFill,
} from "react-icons/ri";
import FooterCard from "./footercard";

export const Footer = () => {
  const handleTwitter = () => {
    window.open("https://x.com/blobcartapp", "_blank");
  };
  const handleFacebook = () => {
    window.open("", "_blank");
  };

  const handleLinkedIn = () => {
    window.open("https://www.linkedin.com/company/blobcart/", "_blank");
  };

  const handleWhatsapp = () => {
    window.open("https://chat.whatsapp.com/Fpht5XUu1uqGHHxn0H7oNd", "_blank");
  };

  const cards = [
    {
      id: 0,
      title: "Blobcart",
      links: [
        { title: "Blog", url: "/blog" },

        {
          title: "Support",
          url: "https://chat.whatsapp.com/Fpht5XUu1uqGHHxn0H7oNd",
        },
        {
          title: "Book A Demo",
          url: "https://cal.com/david-mugalla/30min",
        },
      ],
    },

    {
      id: 2,
      title: "Legal",
      links: [
        { title: "Terms of service", url: "/terms-and-conditions" },
        { title: "Privacy Policy", url: "/privacy-policy" },
      ],
    },
  ];
  return (
    <div className="z-2000 md:w-3/4  md:m-auto border-t border-dark border-opacity-10  mt-[100px] lg:px-56 md:px-auto p-4 flex   md:flex-row flex-col justify-center md:gap-10 items-start  ">
      {cards.map((card) => (
        <FooterCard key={card.id} item={card} />
      ))}
      <div className="flex flex-col justify-start  items-start w-40  md:p-4 gap-5 ">
        <p className=" text-dark font-[500]">Get In Touch</p>

        <div className="flex w-full flex-row  gap-2  items-center">
          <RiTwitterFill
            onClick={() => {
              handleTwitter();
            }}
            className="text-grey hover:text-dark  text-xl cursor-pointer"
          />
          <RiFacebookBoxFill
            onClick={() => {
              handleFacebook();
            }}
            className="text-grey hover:text-dark  text-xl cursor-pointer"
          />
          <RiLinkedinFill
            onClick={() => {
              handleLinkedIn();
            }}
            className="text-grey hover:text-dark text-xl cursor-pointer"
          />
          <RiWhatsappFill
            onClick={() => {
              handleWhatsapp();
            }}
            className="text-grey hover:text-dark text-xl cursor-pointer"
          />
        </div>

        <p className="text-grey hover:text-white  text-[500] mb-auto">
          © 2024 Blobcart
        </p>
      </div>
    </div>
  );
};

import { useEffect, useState } from "react";
import { Button } from "./button";

export const Hero = () => {
  // Array of store names to cycle through
  const storeNames = [
    "Homes-Wear",
    "Store-Flix",
    "Fashion-Hub",
    "ShopZone",
    "Driphub",
    "Mary-Outfits",
    "BaltimoreGrocery",
    "KisauniDrip",
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Function to update the store name index
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % storeNames.length);
    }, 1500); // Change every 3 seconds (3000 milliseconds)

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [storeNames.length]);

  return (
    <div className="flex hero md:flex-row flex-col   justify-between items-center gap-4">
      <div className="w-full mt-[100px]  p-10 flex md:justify-start md:items-start   justify-center items-center flex-col gap-2 md:ml-20 ">
        <div className="relative md:text-start text-center ">
          <h1 className="text-[36px] font-[600]">
            E-commerce tools for{" "}
            <span className="relative">
              Whatsapp{" "}
              <img
                src="https://res.cloudinary.com/do2czykkq/image/upload/f_auto,q_auto/bfgri3hnudtu3fyyaqd6"
                alt="whatsapp-logo"
                className="absolute w-[30px] h-[30px] right-[-25px] bottom-5"
              />
            </span>
          </h1>
          <p className="md:w-[90%] text-dark font-[500]">
            🌍 Sell anywhere, Anytime - Build Your e-commerce store linked to
            your Whatsapp. Simplify ordering and turn conversations to
            conversions. 🛍️
          </p>
        </div>
        <p className="text-grey font-[500] ">
          shop.blobcart.com/{storeNames[currentIndex]}
        </p>
        <div className="w-[200px] h-[50px] relative  md:ml-0 ml-14 ">
          <img
            src="https://res.cloudinary.com/do2czykkq/image/upload/f_auto,q_auto/wnmdofglmd4we6xaebx3"
            alt="shop-logo"
            className="absolute w-[50px] rounded-full shadow-md"
          />
          <img
            src="https://res.cloudinary.com/do2czykkq/image/upload/f_auto,q_auto/h8jf01k24jbxnxaazfc6"
            alt="shop-logo"
            className="absolute w-[50px] rounded-full shadow-md left-5"
          />
          <img
            src="https://res.cloudinary.com/do2czykkq/image/upload/f_auto,q_auto/ymso9y0hghcnszmouule"
            alt="shop-logo"
            className="absolute w-[50px] rounded-full shadow-md left-[50px]"
          />
          <img
            src="https://res.cloudinary.com/do2czykkq/image/upload/f_auto,q_auto/kx7rkbqupa5urtxmlnnd"
            alt="avatar"
            className="absolute w-[50px] rounded-full shadow-md left-[70px]"
          />
          <img
            src="https://res.cloudinary.com/do2czykkq/image/upload/f_auto,q_auto/fbwehuq4jtegi5xowpq8"
            alt="avatar"
            className="absolute w-[50px] rounded-full shadow-md left-[100px]"
          />
        </div>
        <p className="text-grey font-[500]">Join other businesses</p>
        <Button
          title="Get started for free"
          action={() => {
            window.open("https://platform.blobcart.com", "_self");
          }}
        />
      </div>

      <img
        src="https://res.cloudinary.com/do2czykkq/image/upload/f_auto,q_auto/pnsxb4iossespn1jzvb5"
        alt="hero-section-image"
        className="md:w-[50vw]  w-full md:mt-[100px] "
      />
    </div>
  );
};
